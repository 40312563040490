.m-approved {
    background-color: $gray-2;

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media-down(l) {
            margin-top: 150px;
        }

        @include media-down(xs) {
            margin-top: 450px;
        }

        &--title {
            margin-top: 15px;
            text-align: center;
        }
    }

    &__bottom {
        margin-top: 20px;

        .-viewMore {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 30px;
            text-decoration: underline;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            &:hover {
                color: $light-blue;
            }

            @include media-down(s) {
                margin-top: 20px;
            }
        }
    }
}
