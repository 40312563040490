.m-toggler {
    order: 1;
    display: block;
    width: 30px;
    border-radius: 6px;
    bottom: 25px;
    margin-right: 20px;
    cursor: pointer;
    transition: 0.5s;

    @include media-up(l) {
        display: none;
    }

    span {
        display: block;
        height: 2px;
        width: 70%;
        margin: 4px auto;
        background-color: $secondary-color;
        border-radius: 2px;
        cursor: pointer;
    }

    &.-auth {
        margin-left: 22px;
        margin-top: 16px;
    }
}
