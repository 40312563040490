.m-footer {
    padding-top: 70px;
    padding-bottom: 20px;
    background-color: $white;
    border-top: 1px solid $border-color-2;

    @include media-down(l) {
        background-position: left;
    }

    @include media-down(s) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    a {
        width: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        color: $primary-light-color;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;

        &::after {
            content: '';
            position: absolute;
            top: calc(100% + 4px);
            width: 0;
            height: 0.5px;
            background-color: $white;
            transition: 0.3s ease-in-out;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        height: 100%;

        &.-marTop {
            @include media-down(l) {
                margin-top: 30px;
            }

            @include media-down(m) {
                margin-top: 0;
            }
        }

        &.-main {
            @include media-down(l) {
                margin-bottom: 30px;
            }

            @include media-down(s) {
                margin-bottom: 0;
            }
        }

        @include media-down(s) {
            align-items: center;
            text-align: center;
        }

        &--logo {
            @include media-down(s) {
                margin-bottom: 0;
            }

            img {
                min-height: 32px;
                max-height: 32px;
            }
        }

        &--links {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 22px;
            color: $primary-light-color;

            a,
            span {
                margin-top: 13px;
            }

            p {
                margin-top: 13px;
                margin-bottom: 0px;
            }

            @include media-down(s) {
                align-items: center;
            }

            &.-copyright {
                border-top: 1px solid $border-color-2;
                margin-top: 30px;
            }
        }

        .-mainLink {
            margin-top: 3px;
            color: $primary-color;
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;

            @include media-down(s) {
                margin-top: 30px;
            }
        }

        .-copy {
            margin-top: 20px;
        }
    }
}
