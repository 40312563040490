.m-textarea {
    margin-top: 15px;

    label {
        font-size: $font-size-small;
        line-height: 16px;
        font-weight: 600;
        .a-require-star {
            color: $red;
        }
    }

    textarea {
        width: 100%;
        padding: 12px 20px;
        color: $primary-color;
        border: 1px solid $border-color-2;
        border-radius: 4px;
        background-color: $white;
        filter: none;
        resize: none;
        transition: 0.2s;
        outline: none;
        font-size: $font-size-small;

        &:focus {
            border: 1px solid $primary-color;
        }

        &::placeholder {
            opacity: 0.5;
            color: $primary-color;
            font-weight: 300;
            font-size: $font-size-small;
        }
    }

    &.-error {
        textarea {
            border: 1px solid $red;

            &:focus,
            &:active {
                border-color: $red;
            }
        }
    }

    .m-input__error {
        font-size: $font-size-small;
        &.-active {
            color: $red;
        }
    }

    .formNote {
        margin-top: 1rem;
        font-size: 14px;
        font-style: italic;
        color: $main-link;

        &::before {
            font-style: normal;
            position: relative;
            content: '*';
            color: $main-link;
            left: -1px;
        }

        &.-textArea {
            margin-top: 0px;
        }
    }

    &.-textContainer {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
    }
}
