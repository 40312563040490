$white: #fff !default;
$black: #000 !default;
$line: #eff0f3 !default;
$gray-1: #f7f6f4 !default;
$gray-2: #f7f7f9 !default;
$gray-3: #19213226 !default;
$gray-4: #f1f1f2 !default;
$gray-5: #e7e7e7 !default;
$gray-6: #979b9d !default;
$light-blue: #688bb6 !default;
$light-blue-2: #c9d5e3;
$main-link: #898d94 !default;
$table: #fbfbfb !default;

$blue: #3a84f9;
$red: #ff6762;

// Set a specific jump point for requesting color jumps
$color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$contrast-threshold: 150 !default;

$color-info: #3498db;
$color-success: #2ecc71;
$color-warning: #f1c40f;
$color-error: #e74c3c;

$primary-color: #1b1b1b;
$primary-light-color: #484848;
$primary-lighter-color: #f7f6f4;

$secondary-color: #d22128;
$secondary-light-color: #ef2830;

$border-color: #dedede;
$border-color-2: #d3dce5;

$main-color: #f6f6f6;
