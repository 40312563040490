.m-hero {
    margin-top: 60px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 460px;
    background-image: url('../../../images/hero_bg.png');

    @include media-down(m) {
        padding-top: 104px;
        padding-bottom: 375px;
        background-image: url('../../../images/hero_small.png');
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--description {
            margin-top: 20px;
            font-size: 18px;
            line-height: 29px;
        }
    }

    &__campaignImage {
        background-image: url('../../../images/HOMEE23\ 1.png');
        background-size: cover;
        padding: 0;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;

        @include media-down(m) {
            display: none;
        }
    }

    .a-button {
        margin-top: 30px;
    }

    &.-application {
        background-image: url('../../../images/default-bg.jpg');
        background-size: cover;
        padding: 0;
        background-position: center;
        background-repeat: no-repeat;
    }

    &.-noTopMargin {
        margin-top: 0;
    }

    &.-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 100px 0;

        p {
            margin-top: 20px;
            font-size: 18px;
            line-height: 1.6;
            color: $black;
        }
    }

    &.-campaigns {
        background-image: none;
        background-color: white;
        height: 300px;
        text-align: left;

        @include media-down(m) {
            text-align: center;
        }
    }
}
