.m-main {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: 0.5s;
    max-width: $main-closed-mobile-width;
    margin-left: $sidebar-closed-mobile;
    padding-top: $header-height;

    &.-auth {
        padding-top: 0px;
    }

    &.-sidebarIsOpen {
        transition: 0.5s;

        @include media-up(l) {
            max-width: $main-width;
            margin-left: $sidebar-width;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        height: 100%;
        margin: 20px auto;
        padding: 0 20px;

        ._wr {
            max-width: 100%;
            margin: 0;
        }
    }

    &__content {
        width: 100%;
    }

    &__contentContainer {
        padding: 40px 36px 24px;
        background-color: $white;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);

        &.-tabs {
            padding: 0px 36px 24px;
        }

        &.-minHeight {
            min-height: 90vh;
        }
    }
}
