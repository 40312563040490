.m-application {
    .a-button {
        margin-top: 30px;
    }

    &__wrapper {
        flex-wrap: wrap;
        justify-content: flex-start;

        &.-campaignFilters {
            margin-right: 0px;
            margin-left: 0px;
        }
    }

    &.-single {
        color: $black;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 30px;
        cursor: pointer;

        &:hover {
            .m-application__content--wrapper {
                border-color: $secondary-color;
            }
        }

        &.-campaign {
            margin-left: -15px;

            @include media-down(l) {
                margin-left: 0px;
            }
        }
    }

    &__heroIdea {
        height: 200px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            background: linear-gradient(
                180deg,
                rgba(25, 33, 50, 0) 0%,
                #192132 100%
            );
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.2s;
        }

        &--subtitle {
            color: $white;
            position: absolute;
            bottom: 15px;
            left: 20px;
            z-index: 20;
            cursor: pointer;
        }
    }

    &__hero {
        position: relative;
        padding: 20px 20px 0px 20px;

        img {
            width: 100%;
            height: 152px;
            object-fit: cover;
            transition: 0.2s;
            border: none;
            border-radius: 5px;
        }
    }

    &__content {
        padding: 20px;
        transition: 0.2s;

        &.-campaign {
            display: flex;
            justify-content: space-between;
            padding: 20px 20px 15px 20px;
        }

        &--description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            & + p {
                padding-top: 20px;
                border-top: 1px solid $gray-2;
            }
        }

        &--wrapper {
            height: 100%;
            border: 1px solid $gray-3;
            border-radius: 5px;
            background: $white;
            transition: 0.2s;
            display: flex;
            flex-direction: column;
        }

        &--numberOfIdeas {
            padding: 0;
            display: flex;
        }
    }

    &__title {
        padding: 0px 20px 20px 20px;
    }

    &__readMore {
        display: flex;
        margin-top: auto;
        padding-left: 20px;

        p {
            margin-right: 20px;
        }
    }

    &__search {
        .m-inputGroup {
            svg {
                top: 25px;
            }
        }
    }

    &__campaignPage {
        position: relative;
        color: $white;
        padding: 70px 0 165px;
        overflow: hidden;

        &__text {
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;

            &--title {
                margin-top: 15px;
                color: $white;

                @include media-down(l) {
                    max-width: 250px;
                }

                @include media-down(xs) {
                    max-width: 150px;
                }
            }

            @include media-down(l) {
                max-width: 250px;
            }

            @include media-down(xs) {
                max-width: 150px;
            }
        }

        &__image {
            position: absolute;
            top: 0;
            right: 0;
            width: 42vw;
            height: 100%;
            max-width: 610px;
            max-height: 400px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('../../../images/app_img.png');

            @media screen and (min-width: 1600px) {
                background-size: contain;
            }
        }

        .a-button {
            margin-top: 20px;
            background-color: $white;
            color: $black;
            border: 1px solid $white;

            &:hover {
                border: 1px solid $white !important;
                background-color: transparent !important;
                color: $white;
            }
        }
    }

    &__purpose {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        width: 300px;
    }
}
