.p-project {
    &__settingsCard {
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 4px;
        padding: 25px 15px;
        margin-bottom: 25px;

        &.-faqSection {
            flex-direction: column;
        }
    }

    &__cardTitle {
        width: 100%;
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
        color: rgba($primary-color, 0.5);
        border-bottom: 1px solid rgba($primary-color, 0.2);
        padding: 0 0 5px 0;
        margin-bottom: 15px;
    }

    &.-form {
        .p-project__settingsCard {
            border: none;
            padding: 0;

            .a-checkbox {
                white-space: nowrap;
            }
        }
    }

    &__generalSettings {
        .m-inputGroup,
        .a-checkbox {
            &.-disabled {
                opacity: 0.5;

                label,
                input {
                    color: $primary-color;
                }
            }
        }

        .m-textarea {
            textarea {
                min-height: 180px;
                @include scrollbar;
            }
        }

        .m-dateTimePicker {
            &.-disabled {
                input {
                    cursor: default;
                }
            }
        }

        .-userProfile {
            @include media-down(s) {
                flex-direction: column;
            }

            .-submit {
                @include media-down(s) {
                    max-width: 100%;
                }

                button {
                    @include media-down(s) {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    &__singleApplicationButtons {
        display: flex;
        align-items: center;
        justify-content: center;

        .a-button {
            margin: 0 20px;
        }
    }

    &__singleApplicationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .m-selectGroup {
            input {
                padding-left: 40px;
            }
        }

        &--status {
            position: relative;

            svg {
                position: absolute;
                bottom: 15px;
                z-index: 1;
                left: 10px;
            }
        }
    }

    &__fieldArray {
        display: flex;
        flex-direction: column;
        flex: 1;

        .remove {
            cursor: pointer;
            position: absolute;
            right: 64%;
            top: 3.2em;

            @include media-down(m) {
                right: 0;
                top: 0.5em;
            }
        }

        .add {
            margin-top: 20px;
            display: flex;
            cursor: pointer;
            align-items: center;
            width: fit-content;
            padding-left: 15px;

            svg {
                margin-top: 3px;
                margin-right: 5px;
            }
        }

        &--list {
            display: flex;
            position: relative;

            &.-titleTextStepFAQ {
                align-items: baseline;
            }
        }

        &.-titleTextStepFAQ {
            padding-left: 0px;

            &.-row {
                position: relative;
            }
        }
    }
}
