.m-carousel {
  overflow-x: hidden;
  margin: 0 auto;

  &__hero {
    display: none;
    position: relative;
    min-height: 670px;

    @include media-up(m) {
      display: flex;
    }

    img {
      width: 100%;
      height: 670px;
      object-fit: cover;

    }

    &--single {
      width: 100%;
      opacity: 0;
      position: absolute;
      &.active {
        animation: fadeinout .4s;
        opacity: 1;
      }
    }
  }

  &__thumbnails {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 20px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding-bottom: 15px;
    @include scrollbar(5px, $secondary-color);

    &--thumb {
      padding: 0 15px;
      flex: 0 0 auto;
      height: 90px;

      &.active {

        img {
          border: 2px solid $secondary-color;
        }
      }
    }

    img {
      border: 2px solid transparent;
      width: 100%;
      height: 90px;
      object-fit: cover;
    }
  }

  &__actions {
    position: absolute;
    top: 50%;
    border: none;
    z-index: 2;
    background: none;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 20px; height: 20px;
      border-width: 0 0 3px 3px;
      border-style: solid;
      border-color: $white;      
      box-shadow: -5px 5px 5px rgba($black, $alpha: 0.38);      
    }
    
    &.-prev {
      left: 20px;      
      
      &::before {
        transform: rotate(45deg);        
      }
    }

    &.-next {
      right: 20px;

      &::before {
        transform: rotate(225deg);        
      }
    }
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}