.m-dateTimePicker {
    position: relative;
    margin-top: 15px;

    label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;

        .a-require-star {
            color: $red;
        }
    }

    input {
        width: 100%;
        height: 40px;
        padding: 0 15px 0 15px;
        border-radius: 4px;
        border: 1px solid $border-color-2;
        background-color: $white;
        outline: 0;
        color: $primary-color;
        font-size: 13px;
        transition: border-color 0.3s, background-color 0.3s;
        filter: none;

        &::placeholder {
            font-size: $font-size-small;
            color: $primary-color;
            opacity: 0.5;
            font-weight: 300;
        }
    }

    &.-error {
        input {
            border: 1px solid $color-error;

            &:focus {
                border: 1px solid $primary-color;
                outline: none;
            }
        }
    }

    &.-icon {
        input {
            padding-right: 44px;
        }

        &.-label {
            svg {
                top: 38px;
            }
        }
    }

    .a-errorMessage {
        font-size: $font-size-small;
        color: $red;
    }

    svg {
        position: absolute;
        right: 10px;
        top: 38px;
        transition: stroke 0.3s;
    }
}

.flatpickr-calendar {
    &.hasTime {
        height: 70px;

        .flatpickr-time {
            position: relative;
            height: 72px !important;
            max-height: none;

            &.time24hr::before {
                background-color: $main-color;
                color: $primary-color;
                border-top: none;
                border-left: none;
            }

            &::after {
                background-color: $main-color !important;
                color: $primary-color !important;
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
            }

            .numInputWrapper {
                margin-top: 30px;
            }

            .flatpickr-time-separator {
                margin-top: 30px;
                height: auto;
            }
            .flatpickr-minute {
                font-weight: bold;
            }

            input {
                font-weight: bold;
            }

            &::before {
                content: 'Hours';
                position: absolute;
                top: 0;
                width: 50%;
                height: 30px;
                padding-left: 11px;
                color: $white;
                background-color: $primary-color;
                border: 1px solid $gray-3;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &::after {
                content: 'Minutes';
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 30px;
                padding-left: 11px;
                color: $white;
                background-color: $primary-color;
                border: 1px solid $gray-3;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &.arrowTop {
        &::after {
            border-bottom-color: $primary-color !important;
        }
    }

    &.arrowBottom {
        &::after {
            border-top-color: $primary-color !important;
        }
    }

    .flatpickr-month {
        padding-top: 2px;
        background-color: $white !important;
        border-radius: 4px 4px 0 0 !important;
        color: $primary-color;
        height: 40px !important;
        border: 1px solid $border-color;

        .flatpickr-monthDropdown-months {
            background-color: $white !important;

            .flatpickr-monthDropdown-month {
                background-color: $white !important;

                &:hover {
                    background-color: $primary-color !important;
                    transition: 0.3s;
                }
            }
        }
    }

    .flatpickr-current-month {
        color: $primary-color !important;
        fill: $primary-color !important;
        &:hover {
            svg {
                fill: $primary-color !important;
            }
        }
    }

    .numInputWrapper {
        &:hover {
            background-color: $white !important;
        }

        .arrowUp::after {
            border-bottom-color: $primary-color !important;
        }

        .arrowDown::after {
            border-top-color: $primary-color !important;
        }
    }

    .flatpickr-prev-month {
        color: $primary-color !important;
        fill: $primary-color !important;
        &:hover {
            svg {
                fill: $primary-color !important;
            }
        }
    }

    .flatpickr-next-month {
        color: $primary-color !important;
        fill: $primary-color !important;
        &:hover {
            svg {
                fill: $primary-color !important;
            }
        }
    }

    .flatpickr-innerContainer {
        // display: none;
        .flatpickr-weekdays {
            background-color: $main-color !important;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            height: 40px;

            .flatpickr-weekday {
                color: $primary-color !important;
                background-color: $main-color !important;
            }
        }

        .flatpickr-days {
            .flatpickr-day {
                margin-top: 5px;
                border-radius: 4px !important;
                &.prevMonthDay {
                    color: rgba(72, 72, 72, 0.3);
                    background-color: transparent;
                }
                &.nextMonthDay {
                    color: rgba(72, 72, 72, 0.3);
                    background-color: transparent;
                }

                &.today {
                    transition: 0.3s;
                    border: none;
                    background-color: $main-color;

                    &:hover {
                        color: $primary-color;
                        transition: 0.3s;
                    }
                }

                &.selected {
                    background-color: $main-color;
                    color: $primary-color !important;
                    border: none;
                }

                &:hover {
                    background-color: $secondary-color !important;
                    color: $white !important;
                    border: 1px solid transparent;
                    transition: 0.3s;
                }
            }
        }
    }
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    background-color: $primary-color !important;
}
.flatpickr-day.endRange {
    background-color: $primary-color !important;
    border-color: transparent !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.inRange {
    box-shadow: -5px 0 0 $primary-light-color, 5px 0 0 $primary-light-color !important;
}
.flatpickr-day.inRange {
    background-color: $primary-light-color !important;
    border-color: transparent !important;
}
.flatpickr-monthSelect-month.selected {
    background-color: $primary-light-color !important;
}

.flatpickr-monthSelect-theme-dark {
    .flatpickr-months {
        .flatpickr-month {
            border-radius: 4px !important;
        }
    }

    .flatpickr-innerContainer {
        display: none;
    }
}
