// Use $font-path from _vars/_base.scss as path to fonts folder or update it here
// Use https://transfonter.org/ to generate fonts and update font path here
// Default font path (relative to style.css) is ../fonts (that is value of $font-path)

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
        url('../../fonts/Roboto/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    color: $primary-light-color;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('../../fonts/Roboto/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('../../fonts/Roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
        url('../../fonts/Roboto/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
