.m-modal {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba($primary-color, 0.9);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;

    &.-show {
        opacity: 1;
        pointer-events: initial;

        .m-modal__content {
            transform: translateY(0);
        }
    }

    &.-small {
        .wrapper {
            width: min-content;
        }
    }

    .wrapper {
        max-height: 80vh;
        margin: auto;
        width: calc(100% - 50px);
        max-width: 375px;

        @include media-up(m) {
            max-width: 640px;
        }
        @include media-up(l) {
            max-width: 725px;
        }
    }

    &__content {
        background-color: $white;
        padding: 50px;
        overflow: auto;
        box-shadow: 0 4px 8px 0 rgba($black, 0.2),
            0 6px 20px 0 rgba($black, 0.19);
        transition: transform 0.3s;
        transform: translateY(-100%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        font-size: 16px;

        &.-clickworker {
            overflow: inherit;
        }
    }

    &__body {
        width: 100%;
    }

    &__buttons {
        margin-top: 20px;
        display: flex;

        &.-end {
            justify-content: flex-end;
        }

        .a-button {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: transform 0.3s;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 2px;
            background-color: $primary-color;
        }

        &::before {
            transform: translateX(-50%) rotate(45deg);
        }

        &::after {
            transform: translateX(-50%) rotate(-45deg);
        }

        &:hover {
            transform: rotate(90deg);
        }
    }
}
