.m-tab {
    border-bottom: 1px solid rgba($primary-color, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 30px 0 20px;

    @include media-up(l) {
        flex-direction: row;
    }

    &__tab {
        position: relative;
        padding: 5px;
        font-size: 14px;
        line-height: calc(22 / 16);
        font-weight: 600;
        color: rgba($primary-color, 0.5);
        transition: color 0.3s;
        cursor: pointer;
        text-align: center;
        z-index: 1;

        @include media-up(m) {
            text-align: initial;
            padding: 10px;
        }

        @include media-up(xl) {
            padding: 5px 15px;
            font-size: 16px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 2px;
            width: 100%;
            background-color: $secondary-color;
            transition: transform 0.3s;
            transform: scaleX(0);
            pointer-events: none;
            z-index: -1;
        }

        &.-active {
            @include media-up(m) {
                color: $primary-color;
            }

            &::after {
                transform: scaleX(1);
            }
        }
    }

    &.-subcards {
        margin: 30px 0;
        background-color: transparent;
        border-color: transparent;
        border-bottom: 1px solid $gray-2;
    }

    &.-companyForm {
        @include media-down(xl) {
            flex-direction: column;
        }

        .m-tab__tab {
            @include media-up(xl) {
                padding: 15px 25px;
                font-size: 14px;
            }
        }
    }
}
