.p-notFoundPage {
    text-align: center;

    &__text {
        margin-bottom: 20px;
    }

    &__image {
        margin-bottom: 20px;

        @include media-down(s) {
            width: 150px;
        }
    }
}
