.m-auth {
    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: 135vh;
        justify-content: space-between;
    }

    &__main {
        width: 100%;
        min-height: 107vh;
        margin-top: 64px;
        padding: 20px 15px;
    }

    &__campaigns {
        min-height: 102vh;
    }

    &__notFound {
        min-height: 75vh;

        @include media-down(l) {
            min-height: 56vh;
        }

        @include media-down(s) {
            min-height: 46vh;
        }
    }
}
