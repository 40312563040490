.a-breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        color: $primary-color;
        margin-right: 25px;

        &::after {
            content: '';
            position: absolute;
            right: -15px;
            display: inline-block;
            border-width: 0 1px 1px 0;
            border-color: $primary-color;
            border-style: solid;
            width: 6px;
            height: 6px;
            transform: rotate(-45deg);
        }
    }

    &__item:last-child {
        color: $primary-color;
        opacity: 0.5;
        cursor: default;

        &::after {
            display: none;
        }
    }
}
