.m-radioGroupContainer {
    margin-top: 15px;

    &__options {
        margin-top: 5px;
    }

    &__label {
        font-size: $font-size-small;
        line-height: 16px;
        font-weight: 600;
    }

    .m-radioGroup {
        display: flex;

        input {
            display: none;
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0 20px 5px 0;

            .radio {
                height: 20px;
                width: 20px;
                border: 1px solid $gray-3;
                border-radius: 10px;
                border-radius: 50%;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                transition: background-image 0.3s, box-shadow 0.3s,
                    border-color 0.3s;

                &::after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: $primary-color;
                    transform: scale(0);
                    transition: transform 0.3s;
                }
            }

            .label {
                font-size: 16px;
                line-height: 18px;
            }
        }

        input:checked + label {
            .radio {
                &::after {
                    transform: scale(1.2);
                }
            }
        }
    }
}
