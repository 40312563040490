.m-card {
    min-height: 100vh;

    &.-bg {
        background: $white;
        width: 100%;
        height: 100%;
    }

    &.-dark {
        background-image: url('../../../images/dark-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-left: 0;
        padding-right: 0;
    }

    &.-form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &__form {
        margin: auto 0;
        width: 100%;

        &.-singleApplication {
            button {
                margin-top: 0px;
            }
        }

        &.-statusMessage {
            text-align: center;
            min-height: 70vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.-register {
            margin-top: 65px;
        }

        form {
            margin-top: 40px;
        }

        button {
            width: 100%;
            margin-top: 20px;

            div {
                display: flex;
                justify-content: center;
            }
        }

        a {
            font-size: $font-size-small;
            display: inline-block;
            width: 100%;
            text-align: right;
            text-decoration: underline;
            font-weight: 600;

            &.-previewDraft {
                margin-top: 10px;
                justify-content: center;
            }
        }

        .m-modal {
            display: flex;
            justify-content: center;
            align-content: center;

            &__body {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                h2,
                h4 {
                    text-align: center;
                }
            }

            form {
                width: 100%;
            }
        }

        .info {
            font-weight: 300;
            display: inline-block;
            margin: 40px 0 30px;
        }

        &--action {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            a {
                display: flex;
            }
        }

        &.-checkEmail {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                text-align: center;
            }
        }

        h4 {
            &.-email {
                margin-top: 20px;
                font-weight: 400;

                @include media-down(m) {
                    font-size: 18px;
                }
            }
        }

        .userProfile {
            display: flex;
            align-items: center;

            .m-fileDragAndDrop {
                margin-right: 30px;
            }
        }
    }

    &__img {
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;

        @include media-up(xxl) {
            background-position: left;
        }
    }

    &__confirmation {
        font-size: $font-size-small;
        margin-top: 20px;
        font-weight: 600;

        span {
            color: rgba($primary-color, 0.5);

            &.-link {
                color: $primary-color;
                text-decoration: underline;
                margin-left: 3px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        & + button {
            margin-top: 30px;
        }
    }

    &__subsection {
        display: flex;
        flex-wrap: wrap;
        &:not(:first-child) {
            .m-card__subsection--title {
                margin-top: 20px;
            }
        }

        &--title {
            & > span {
                display: inline-block;
                padding-bottom: 5px;
                border-bottom: 1px solid rgba($primary-color, 0.5);
                width: 100%;
                font-weight: 300;
                color: $primary-color;
                opacity: 0.5;
            }
        }
    }

    &.-noAuth {
        display: none;
        @include media-up(l) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: fixed;
            right: 0;
        }

        .m-card__img {
            @include media-up(m) {
                background-image: url('../../../images/image\ 74\ 1.png');
            }

            &.-voter-register {
                @include media-up(m) {
                    background-image: url('../../../images/voter_register_bg.png');
                }
            }
        }
    }

    &.-p {
        @include media-up(m) {
            padding: 52px 75px;
        }
    }

    .m-modal {
        @include media-up(l) {
            max-width: calc(6 / 12 * 100%);
            flex: 0 0 calc(6 / 12 * 100%);
        }
    }

    .-registrationButton {
        flex: 0 0 33%;
        max-width: 33%;
    }
}
