@mixin scrollbar($width: 5px, $thumbBackground: $primary-color) {
    &::-webkit-scrollbar {
        width: $width;
        height: $width;
    }

    &::-webkit-scrollbar-track {
        background: $gray-2;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumbBackground;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: darken($thumbBackground, 5%);
    }
}
