.m-management {
    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        column-gap: 1rem;

        @include media-down(m) {
            flex-direction: column;
            row-gap: 1rem;
        }

        &.-centerText {
            text-align: center;
        }

        &.-modal {
            margin-bottom: 25px;
        }
    }

    &__title {
        text-align: center;
        font-weight: 600;
    }

    &__importGroup {
        position: relative;
        margin-top: 2.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    &__importInput {
        display: none;
    }

    &__table {
        margin-top: 2rem;
        width: 100%;
        text-align: center;
        overflow-x: auto;

        &--note {
            color: $secondary-color;
            font-weight: 600;
        }

        table {
            width: 100%;
        }

        td,
        th {
            border: 1px solid $gray-3;
            padding: 8px;
        }
    }

    &__form {
        .formNote {
            margin-top: 1rem;
            font-size: 14px;
            font-style: italic;
            color: $main-link;

            &::before {
                font-style: normal;
                position: relative;
                content: '*';
                color: $red;
                left: -1px;
            }
        }
    }
}
