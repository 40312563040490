.-eighteen {
    font-size: 18px;
    line-height: 29px;
}

.-sixteen {
    font-size: 16px;
    line-height: 20px;
}

.-thirteen {
    font-size: 13px;
    line-height: 15px;
}

.-subtitle {
    color: $red;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
}

.-secondaryTitle {
    font-weight: 400;
    margin-top: 20px;
}

.-gray {
    color: $main-link;
}
