.m-castYourVoteModal {
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.-castYourVoteModalText {
    text-align: center;

    @include media-down(m) {
        font-size: 16px;
    }
}