.m-table {
    table-layout: fixed;
    position: relative;

    ::-webkit-scrollbar {
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba($primary-light-color, 0.2);
        border-radius: 4px;
        opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
        background: $primary-light-color;
        border-radius: 4px;
    }

    &__container {
        display: block;
        overflow-x: auto;
        border-radius: 3px;
        border: 1px solid $border-color;
    }

    table {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    thead {
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: -0.2px;
        background-color: $primary-light-color;
        color: $white;

        th {
            border: none;
            padding: 12px 15px;
            border-bottom: 1px solid $gray-1;

            &:first-child {
                padding-left: 30px;
            }

            @include media-down(xs) {
                padding: 12px 10px;
            }

            div {
                display: flex;

                svg {
                    margin-left: 5px;
                }
            }

            .a-checkbox__container {
                margin: 0;
            }
        }
    }

    tbody {
        td {
            padding: 12px 15px;
            font-size: 14px;
            line-height: 26px;
            max-width: 300px;
            vertical-align: top;
            color: $primary-light-color;

            &:first-child {
                padding-left: 30px;
            }

            &:last-child {
                min-width: 50px;
            }

            @include media-down(xs) {
                padding: 10px;
                font-size: 12px;
            }

            .a-checkbox__container {
                margin-top: 0;
            }
        }

        .m-table__empty {
            text-align: center;
            height: 100%;

            &:hover {
                background-color: $white;
                cursor: default;
            }
        }

        tr:nth-child(odd) {
            background-color: $white;
        }

        tr:nth-child(even) {
            background-color: $table;
        }

        tr {
            transition: background-color 0.2s ease;

            &.-selected {
                background-color: $light-blue-2;

                &:hover {
                    cursor: pointer;
                    background-color: $light-blue-2 !important;
                }
            }
        }

        td.clickableCell {
            &:hover {
                cursor: pointer;
            }
        }

        .m-table__placeholder {
            height: 100%;
            position: relative;
            td {
                span {
                    background-color: $primary-color;
                    width: 100%;
                    height: 16px;
                    display: block;
                }
            }
        }

        tr:nth-child(odd).m-table__placeholder {
            td {
                span {
                    background-color: rgba($primary-color, 0.4);
                }
            }
        }

        tr:nth-child(even).m-table__placeholder {
            td {
                span {
                    background-color: rgba($primary-color, 0.3);
                }
            }
        }
    }

    &__actions {
        text-align: right;

        span {
            cursor: pointer;
            margin-left: 15px;
        }

        .disabledAction {
            cursor: default;

            svg {
                stroke: $main-link;
            }
        }
    }

    .pagination-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 20px;

        @include media-up(l) {
            flex-direction: row;
            align-items: center;
        }

        .pagination-selector {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 19px;
            color: $primary-color;

            select {
                margin: 0 8px;
                border-radius: 3px;
                padding: 2px 10px;
                background-color: $white;
                border-color: $primary-color;

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    .pagination {
        display: inline-flex;
        align-items: center;
        padding: 4px;
        background-color: $white;
        border: 1px solid $gray-1;
        border-radius: 5px;
        margin-top: 10px;
        left: 0;

        @include media-up(l) {
            margin-top: 0;
        }

        .item {
            cursor: pointer;
            color: $black;
            min-width: 36px;
            height: 36px;
            display: flex;
            padding: 0 5px;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            text-decoration: none;
            user-select: none;
            transition: background-color 0.3s;
            font-size: 14px;
            line-height: 19px;

            &:hover {
                background-color: $gray-2;
            }

            &.active {
                background-color: $secondary-color;
                color: $white;
            }

            &.disabled {
                pointer-events: none;
                color: $gray-1;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media-down(m) {
            padding-bottom: 10px;

            @include media-down(s) {
                padding-bottom: 0;
            }
        }

        .a-button {
            margin-left: 5px;
            line-height: 16px;
        }
    }

    &__rowActions {
        display: none;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        max-height: 0;
        width: 100%;
        transition: max-height 0.3s, opacity 0.4s,
            background-color 0.4s ease-out;
        background-color: $main-color;
        border-radius: 6px;
        padding: 0.5rem 0.25rem;

        &.-show {
            // min-height: 50px;
            display: flex;
            gap: 1rem;
            min-height: 100%;
            max-height: 1000px;
            transition: max-height 0.3s, opacity 0.4s,
                background-color 0.4s ease-out;

            @include media-down(s) {
                gap: 0.25rem;
            }

            .m-table__rowActionsTitle {
                display: flex;
            }
        }

        .m-table__rowActionsTitle {
            display: inline-block;
            font-size: 14px;
            transition: opacity 0.4 ease-out;

            @include media-down(m) {
                padding-bottom: 10px;

                @include media-down(s) {
                    padding-bottom: 0;
                }
            }
        }

        .m-table__actions {
            row-gap: 0.5rem;
            column-gap: 1rem;
            flex-wrap: wrap;

            .a-button {
                margin: 3px;
            }
        }
    }

    &__searchContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 15px;
        flex-wrap: wrap;

        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 1rem;

            .a-button {
                &.-basic {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $white;
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    max-height: 44px;
                    margin-top: 10px;
                    padding: 1rem;

                    div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        column-gap: 0.5rem;
                    }
                    svg {
                        margin-right: 0;
                    }
                }
            }

            @include media-down(m) {
                padding-bottom: 10px;
            }
        }

        .a-button {
            &.-primary {
                svg {
                    stroke: $white;
                    transition: stroke 0.3s ease-in-out;
                }
            }
        }
    }

    &__actionsContainer {
        position: relative;
        width: 100%;

        @include media-up(l) {
            width: calc(100% - 356px);

            &.-full {
                width: 100%;
            }
        }
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        width: 100%;

        .m-inputGroup,
        .m-selectGroup,
        .m-dateTimePicker {
            width: 100%;
            margin-right: 15px;
            margin-bottom: 0;

            @include media-up(xl) {
                width: 200px;
            }
        }

        .a-checkbox {
            margin-top: 22px;
        }

        &--reset {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;

            @include media-up(xl) {
                margin-top: 40px;
            }

            span {
                margin-left: 10px;

                &:hover {
                    cursor: pointer;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__filterButton {
        width: 40px;
    }

    &__search {
        .m-inputGroup {
            width: 320px;

            @include media-down(s) {
                width: 260px;
            }
        }
        svg {
            top: 24px;
            left: 14px;
        }
    }

    &.-loading {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, $white 0%, transparent 100%);
        }
    }

    &__invited {
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
    }

    &__approved {
        display: flex;
        align-items: center;

        svg {
            flex: 0 0 auto;
            margin-right: 5px;
        }

        span {
            white-space: nowrap;
        }
    }

    &__search-action__wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        min-height: 80px;

        .a-checkbox__container {
            margin-right: 15px;
            margin-bottom: 15px;
            align-self: flex-end;
        }

        @include media-down(xl) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
