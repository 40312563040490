.m-fileDragAndDrop {
    width: 100%;
    margin-top: 20px;

    .a-file {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        align-items: center;
        padding: 15px;
        background-color: $gray-2;
        border: 1px dashed $gray-3;
        font-size: 14px;
        transition: 0.3s;
        cursor: pointer;

        &:hover,
        &:focus {
            border: 1px dashed $primary-color;
            transition: 0.3s;
        }

        &.-error {
            border-color: $red;

            &:hover,
            &:focus {
                border-color: $red;
            }
        }

        p,
        label {
            margin: 5px 0 0 10px;

            @include media-down(m) {
                margin: 5px 0 0 0;
            }
        }

        p {
            font-size: 13px;

            &.-action {
                font-weight: 600;
            }
        }

        .textContainer {
            margin-left: 5px;
        }

        .imageContainer {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 120px;
            }

            .image {
                max-width: 100%;
                object-fit: contain;
            }

            .a-file__multiple--remove {
                right: -0.5rem;
            }
        }

        &__multiple {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            justify-content: center;

            @include media-up(l) {
                justify-content: flex-start;
            }

            div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-right: 20px;
                margin-top: 20px;

                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                }
            }

            &--content {
                position: relative;
            }

            &--remove {
                position: absolute;
                cursor: pointer;
                top: -0.5rem;
                right: 0.75rem;
            }
        }
    }

    video {
        max-width: 100%;
        justify-self: center;
    }

    label {
        font-size: 18px;
        font-weight: 600;
    }

    img {
        height: 100px;
    }

    .formNote {
        margin-top: 1rem;
        font-size: 14px;
        font-style: italic;
        color: $main-link;

        &::before {
            font-style: normal;
            position: relative;
            content: '*';
            color: $main-link;
            left: -1px;
        }
    }

    &.-avatar {
        margin-top: 0;
        width: 112px;

        .a-file {
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            &:hover,
            &:focus {
                border: none;
            }
        }

        .imageContainer {
            border: 1px solid #b4b9c4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100px;
            height: 100px;

            .image {
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
                width: 100px;
                height: 100px;
            }

            .a-file__multiple--remove {
                right: -2px;
                top: -5px;
                svg {
                    circle {
                        fill: $main-color;
                    }
                    path {
                        stroke: $black;
                    }
                }
            }
        }

        .editImage {
            position: absolute;
            right: -3px;
            bottom: -3px;
            cursor: pointer;
        }
    }
}
