.a-button {
    border-style: none;
    border-radius: 4px;
    background-color: transparent;
    color: $white;
    font-size: 16px;
    line-height: 26px;
    transition: 0.3s ease-in-out;
    font-weight: 500;
    cursor: pointer;
    width: 180px;
    padding-top: 15px;
    padding-bottom: 15px;

    &:active,
    &:focus {
        outline: none;
    }

    &.-primary {
        border: 1px solid transparent;
        background-color: $secondary-color;
        color: $white;

        &:not(.-disabled) {
            &:hover {
                background-color: $secondary-light-color;
                color: $white;

                svg {
                    stroke: $white;
                }
            }
        }
    }

    &.-secondary {
        background-color: $white;
        color: $secondary-color;
        border: 1px solid $secondary-color;

        svg {
            stroke: $white;
        }

        &:not(.-disabled) {
            &:hover {
                background-color: #fff0ee;
            }
        }
    }

    &.-basic {
        background-color: transparent;
        color: $primary-color;
    }

    &.-disabled {
        background-color: $gray-5;
        color: $gray-6;
        cursor: default;
    }

    &.-loadMore {
        text-decoration: underline;
        color: $black;
    }

    &.-buttonWithIcon {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    &.-exportButton {
        width: 100px;
    }

    &.-voteButton {
        height: 60px;
    }

    &__icon {
        display: flex;
        align-items: center;
        svg {
            margin-right: 15px;
        }
    }
}
